<template>
  <v-container id="about" tag="section">
    <base-subheading>About</base-subheading>

    <p>
      A fan site for the best integrated amp of the 90's <tt>:-)</tt>
    </p>
    <p>
      There's also some info on the PMA 260, 360, 860, and 1060 (nothing on the 1560 yet).
    </p>
  </v-container>
</template>

<script>
  export default {
    name: 'HomeAbout',
  }
</script>
